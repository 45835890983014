import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Discrepancy, ILeadsToBulkSearchParams, ILoadDiscrepanciesResponse } from '../model/Discrepancy';
import { DiscrepancyLeadToBulk, ISelectsData } from '../model/DiscrepancyLeadToBulk';
import { Faq, FaqApiResponse } from '../model/FaqData';
import { AppConstants } from '../resources/app-constants';

@Injectable({
  providedIn: 'root',
})
export class FaqDataService {
  private baseUrl = environment.apiBaseURL;
  constructor(private httpClient: HttpClient) {}

  getFaq(
    key = '',
    page = 0,
    limit = AppConstants.CONTACTS_LIMIT,
    sortCol = 'name',
    sortDirection = 'asc'
  ): Observable<FaqApiResponse> {
    const offset = (page-1) * limit;    
    const keyFilter = key ? `&filter=${key}` : '';
    switch(sortCol) {
      case 'date':
        sortCol = 'date_created';
        break;
      case 'client':
        sortCol = 'client_name';
        break;
    }
    const queryUrl = `${this.baseUrl}/app/faq/?limit=${limit}&offset=${offset}&order_by=${sortCol}&order_type=${sortDirection}${keyFilter}`;
    return this.httpClient
      .get(queryUrl)
      .pipe(
        map((response: any) => {
          const faqs : Faq[] = [];
          response.data.forEach(faq => {
            faqs.push(new Faq({
              id: faq.id,
              client: faq.client_name,
              title: faq.title,
              date: faq.date_created
            }))
          });
          return {
            items: faqs,
            total_count: response.total
          };
        })
      );
  }

  removeFaq(faqId: string): Observable<any>{
    const url = `${environment.apiBaseURL}/app/faq?id_faq=${faqId}`;

    return this.httpClient.delete<any>(url, { observe: 'response' }).pipe(map(response => response));
  }

  loadFaq(faqId) {
    const endpoint = environment.apiBaseURL + '/app/faq/' + faqId;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  loadFaqByClient(clientId): Observable<any> {
    const endpoint = environment.newApiBaseUrl + '/configuration/faq/get-by-customer/' + clientId;
    return this.httpClient.get(endpoint);
  }

  createOrUpdateFaq(
    mode,
    faqId,
    title,
    clientId,
    faqText,
    userCreated,
    userUpdated
  ) {
    let endpoint = environment.apiBaseURL + '/app/faq';
    const body: any = {
      id: faqId,
      title,
      client_id: clientId,
      faq_text: faqText,
      user_created: userCreated,
      user_updated: userUpdated
    };

    if (mode === 'new') {
      return this.httpClient.post(endpoint, body).pipe(map((response: any) => {
        response.status = response.code;
        return response;
      }));
    } else {
      return this.httpClient.put(endpoint, body, { observe: 'response' }).pipe(map((response: any) => response));
    }
  }

}
