export interface ITemplate {
    id: number;
    date: string;
    title: string;
    attachment: boolean;
    sdr: string;
    clientContact: string;
    notes: string;
    isSelected: boolean;
    bcc: string;
    cc: string;
    clientContactEmail: string;
    clientContactId: number;
    clientId: number;
    tags: string;
    templateText: string;
    templateTypeId: number;
    isInternalUse: boolean;
    sortDate: Date;
    hasAttachments: boolean;
    client: string;
  }
export interface ITemplateDeleteResponse {
    data: number;
    message: string;
    status: string;
    errors: string;
}

export class Template implements ITemplate {
  id: number;
  date: string;
  title: string;
  attachment: boolean;
  sdr: string;
  clientContact: string;
  notes: string;
  bcc: string;
  cc: string;
  clientContactEmail: string;
  clientContactId: number;
  clientId: number;
  tags: string;
  templateText: string;
  templateTypeId: number;
  isSelected: boolean;
  isInternalUse: boolean;
  sortDate: Date;
  hasAttachments: boolean;
  client: string;

  constructor(template) {
    this.id = template.templateId;
    this.date = this.formatDate(new Date(template.dateCreated));
    this.title = template.title;
    this.attachment = template.attachment != undefined ? JSON.parse(template.attachment): [];
    this.sdr = template.sdr;
    this.clientContact = template.clientContact;
    this.notes = template.notes;
    this.bcc =  template.bcc;
    this.cc = template.cc;
    this.clientContactEmail = template.clientContactEmail;
    this.clientContactId = template.clientContactId
    this.clientId = template.clientId;
    this.tags = template.tags;
    this.templateText = template.templateText;
    this.templateTypeId = template.templateTypeId;
    this.isSelected = false;
    this.isInternalUse = template.internalUseOnly;
    this.sortDate = new Date(template.dateCreated);
    this.hasAttachments = template.attachment && template.attachment.length;
    this.client = template.client;
  }

  formatDate(date) {
    return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}