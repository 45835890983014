import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, retry, switchMap, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { DatepickerDialogComponent } from '../../../components/datepicker-dialog/datepicker-dialog.component';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { ClassificationStatus } from '../../../model/common/classification-status.model';
import { Contact } from '../../../model/common/contact.model';
import { ConvertedDialogData } from '../../../model/common/converted-dialog-data.model';
import { ResponseStatus } from '../../../model/common/generic-response';
import { Interaction } from '../../../model/common/interaction.model';
import { StatusScreen } from '../../../model/common/status-screen.enum';
import { DatepickerDialogData } from '../../../model/datepicker-dialog/datepicker-dialog-data.model';
import { ForwardedInteractionDialogData } from '../../../model/interaction/forwarded-interaction-dialog-data.model';
import { ClassifierMessage } from '../../../model/mail-classifier/classifier-message.model';
import { ClassifyReviewEmailDto } from '../../../model/mail-classifier/classify-review-email.dto';
import { MessageToClassifyDto } from '../../../model/mail-classifier/message-to-classify.dto';
import { LoadingStateService } from '../../../services/base/loading-state.service';
import { ClassifierLogService } from '../../../services/classifier-log.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { InteractionService } from '../../../services/interaction.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { BREAKPOINT_1280, BREAKPOINT_1450, BREAKPOINT_1650 } from '../../../utils/constants';
import { ConvertedDialogComponent } from '../../shared/converted-dialog/converted-dialog.component';
import { ForwardedInteractionDialogComponent } from '../../shared/forwarded-interaction-dialog/forwarded-interaction-dialog.component';

@Component({
  selector: 'app-mail-classification',
  templateUrl: './mail-classification.component.html',
  styleUrls: ['./mail-classification.component.scss'],
})
export class MailClassificationComponent implements OnInit, OnDestroy {
  @ViewChild('overlayContent') overlayContent!: TemplateRef<any>;
  @ViewChild('toggleButton', { read: ViewContainerRef }) private buttonRef!: ViewContainerRef;
  statuses: ClassificationStatus[] = [];
  classificationStatus = ClassificationStatusEnum;
  statusScreen = StatusScreen;
  subscriptions = new Subscription();
  selectedInteraction: Interaction = null;
  currentContact: Contact = null;
  selectedMessage: ClassifierMessage = null;
  statusesToShow = 6;
  isReview = false;
  breakPointsNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [BREAKPOINT_1280, 'XSLarge'],
    [BREAKPOINT_1450, 'MLarge'],
    [BREAKPOINT_1650, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);
  breakpointValueMap = new Map([
    [this.breakPointsNameMap.get(Breakpoints.XSmall), 1],
    [this.breakPointsNameMap.get(Breakpoints.Small), 3],
    [this.breakPointsNameMap.get(Breakpoints.Medium), 6],
    [this.breakPointsNameMap.get(BREAKPOINT_1280), 3],
    [this.breakPointsNameMap.get(BREAKPOINT_1450), 4],
    [this.breakPointsNameMap.get(BREAKPOINT_1650), this.isReview ? 6 : 12],
    [this.breakPointsNameMap.get(Breakpoints.XLarge), this.isReview ? 7 : 14],
  ]);
  currentScreenSize: string;
  convertedInteraction: Interaction = null;
  convertedChannelInteraction: Interaction = null;
  blackListClassifications = [
    'Client',
    'Competitor',
    'Bad Fit CO.',
    'Unsubscribe CO.',
    'Closed CO.',
    'Abuse Report CO.',
  ];
  directClassifications = ['Other', 'Inbox'];
  clientId: number = null;
  private destroy$: Subject<boolean> = new Subject();

  private overlayRef: OverlayRef | null = null;
  isOverlayOpen = false;

  constructor(
    private overlay: Overlay,
    private configurationService: ConfigurationService,
    private classifierLogService: ClassifierLogService,
    private dialog: MatDialog,
    private interactionService: InteractionService,
    private loadingStateService: LoadingStateService,
    private mailReviewService: MailReviewService,
    private snackBarService: SnackBarService,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        BREAKPOINT_1280,
        BREAKPOINT_1450,
        BREAKPOINT_1650,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.breakPointsNameMap.get(query) ?? 'Unknown';
            this.statusesToShow = this.breakpointValueMap.get(this.currentScreenSize) || 0;
          }
        }
      });
  }

  ngOnInit(): void {
    this.mailReviewService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;
    });

    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.selectedMessage = message;
    });

    this.mailReviewService.currrentContact$.pipe(takeUntil(this.destroy$)).subscribe((contact) => {
      this.currentContact = contact;
    });

    this.mailReviewService.convertedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.convertedInteraction = interaction;
    });

    this.mailReviewService.convertedChannelInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.convertedChannelInteraction = interaction;
    });

    this.mailReviewService.currentStatusScreen$.pipe(takeUntil(this.destroy$)).subscribe((statusScreen) => {
      this.isReview = statusScreen === this.statusScreen.Review;
      this.loadStatuses();
    });

    this.configurationService.clientRepresentatives$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (clientRepresentatives) => {
        this.clientId = clientRepresentatives?.clientId || null;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadStatuses() {
    this.configurationService
      .getClassificationStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ClassificationStatus[]) => {
        const priorityKey = this.isReview ? 'reviewPriority' : 'manualPriority';

        if (!response?.length || !response[0]?.hasOwnProperty(priorityKey)) {
          this.statuses = [];
          return;
        }
        this.statuses = response.filter((x) => x[priorityKey] !== null).sort((a, b) => a[priorityKey] - b[priorityKey]);
      });
  }

  handleToContactAndOutOfOffice(newStatus: ClassificationStatus) {
    const isRequired = newStatus.ruleDescription === ClassificationStatusEnum.ToContact ? true : false;
    const datePickerDialogConfig = new MatDialogConfig();
    datePickerDialogConfig.data = {
      required: isRequired,
      defaultDate: this.selectedMessage.returnDate,
    } as DatepickerDialogData;
    const datePickerDialogRef = this.dialog.open(DatepickerDialogComponent, datePickerDialogConfig);
    datePickerDialogRef.afterClosed().subscribe((date) => {
      if (date === false || date === undefined) {
        return;
      }

      if (!date && isRequired) {
        return;
      }

      if (!date && !isRequired) {
        this.handleClassifyEmailMatched(newStatus);
        return;
      }

      this.handleClassifyEmailMatched(newStatus, date);
    });
  }

  classify(event: Event, newStatus: ClassificationStatus) {
    event.stopPropagation();
    this.closeOverlay();

    if (newStatus.ruleDescription === ClassificationStatusEnum.Inbox) {
      this.handleClassifyEmail(newStatus);
      return;
    }

    if (newStatus.ruleDescription === ClassificationStatusEnum.Forwarded && this.selectedInteraction) {
      this.dialog.open(ForwardedInteractionDialogComponent, {
        data: {
          ...this.getForwardedDialogData(),
        } as ForwardedInteractionDialogData,
        minWidth: '40vw',
        hasBackdrop: false,
      });
      return;
    }

    if (this.isReview) {
      if (newStatus.ruleDescription === ClassificationStatusEnum.Converted && this.selectedInteraction) {
        this.dialog.open(ConvertedDialogComponent, {
          data: {
            ...this.getConvertedDialogData(newStatus),
          } as ConvertedDialogData,
          minWidth: '40vw',
          hasBackdrop: false,
        });
        return;
      }

      if (newStatus.ruleDescription === ClassificationStatusEnum.ConvertedChannel && this.selectedInteraction) {
        this.dialog.open(ConvertedDialogComponent, {
          data: {
            ...this.getConvertedDialogData(newStatus),
            isConvertedChannel: true,
          } as ConvertedDialogData,
          minWidth: '40vw',
          hasBackdrop: false,
        });
        return;
      }

      this.handleClassifyEmailMatched(newStatus);
    } else {
      if (this.selectedMessage.statusName !== newStatus.statusName) {
        const confirmDialogConfig = new MatDialogConfig();
        confirmDialogConfig.data = {
          title: 'Classification',
          message: `The current status of the message is not the same as the new classification ${newStatus.statusName}, do you want to continue?`,
        };
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
        confirmDialogRef.afterClosed().subscribe((confirm) => {
          if (!confirm) {
            return;
          } else {
            this.handleManualClassification(newStatus);
            return;
          }
        });
      } else {
        this.handleManualClassification(newStatus);
      }
    }
  }

  private handleManualClassification(newStatus: ClassificationStatus) {
    if (this.blackListClassifications.includes(newStatus.ruleDescription)) {
      this.handleBlacklist(newStatus);
      return;
    }
    if (this.directClassifications.includes(newStatus.ruleDescription)) {
      this.handleClassifyEmail(newStatus);
      return;
    }

    if (newStatus.ruleDescription === 'Delete From') {
      this.handleDeleteFrom(this.selectedMessage.email.fromMail, newStatus);
      return;
    }

    if (
      newStatus.ruleDescription === ClassificationStatusEnum.ToContact ||
      newStatus.ruleDescription === ClassificationStatusEnum.OutOfOffice
    ) {
      this.handleToContactAndOutOfOffice(newStatus);
      return;
    }

    this.handleClassifyEmailMatched(newStatus);
  }

  private handleBlacklist(newStatus: ClassificationStatus) {
    const { customerId } = this.selectedMessage;
    const { emailAddress } = this.currentContact;

    this.loadingStateService.setLoadingState(true);

    this.classifierLogService
      .checkBlacklistDomain(emailAddress, customerId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe((response) => {
        const isBlacklisted = response;
        if (!isBlacklisted) {
          this.handleBlacklistDialog(emailAddress, newStatus);
        } else {
          this.callHandleClassifyApi(newStatus)
            ? this.handleClassifyEmail(newStatus)
            : this.handleClassifyEmailMatched(newStatus);
        }
      });
  }

  private handleDeleteFrom(fromMail: string, newStatus: ClassificationStatus) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Classification',
      message: `The email ${fromMail} will be added to the delete from list, do you want to add it?`,
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((addToDeleteFromList) => {
      addToDeleteFromList
        ? this.handleClassifyEmail(newStatus)
        : this.snackBarService.showWarning('No action was executed');
    });
  }

  private handleBlacklistDialog(fromMail: string, newStatus: ClassificationStatus) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Classification',
      message: `The domain ${fromMail.split('@')[1]} is not on the blacklist, do you want to add it?`,
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((addToBlacklist) => {
      addToBlacklist
        ? this.callHandleClassifyApi(newStatus)
          ? this.handleClassifyEmail(newStatus)
          : this.handleClassifyEmailMatched(newStatus, '', addToBlacklist, fromMail)
        : this.snackBarService.showWarning('No action was executed');
    });
  }

  private callHandleClassifyApi(newStatus: ClassificationStatus) {
    return (
      newStatus.ruleDescription === ClassificationStatusEnum.ClosedCompany ||
      newStatus.ruleDescription === ClassificationStatusEnum.AbuseReportCompany
    );
  }

  private getConvertedDialogData(newStatus): ConvertedDialogData {
    const { ruleId, ruleDescription } = newStatus;
    const { customerId } = this.selectedMessage;
    const { id: messageHandlerId, fromMail, messageId, sdrId, sentDate } = this.selectedMessage.email;
    const convertedDialogData: ConvertedDialogData = {
      fromMail,
      ruleId,
      ruleDescription,
      messageHandlerIds: [messageHandlerId],
      isMessageClassification: false,
      interaction: this.selectedInteraction,
      contact: this.currentContact,
      replyMessageId: messageId,
      messageId,
      sdrId,
      interactionUtcDatetime: sentDate,
      customerId,
    };
    return convertedDialogData;
  }

  private getForwardedDialogData(): ForwardedInteractionDialogData {
    const { interactionId, utcDatetimeCreated: interactionUtcDatetime, sdrId } = this.selectedInteraction;
    const { messageId } = this.selectedMessage.email;
    const { contactId } = this.currentContact;

    const forwardedInteractionDialogData: ForwardedInteractionDialogData = {
      sdrId,
      interactionId,
      contactId,
      interactionUtcDatetime,
      messageId,
    };
    return forwardedInteractionDialogData;
  }

  private handleClassifyEmailMatched(
    newStatus: ClassificationStatus,
    returnDateFromDialog?: string,
    isBlacklisted: boolean = false,
    fromMail?: string,
  ) {
    const { interactionId, sdrId } = this.selectedInteraction || {};
    const {
      email: { id, sentDate, uid, sdr, messageId },
      returnDate,
    } = this.selectedMessage;
    const { contactId } = this.currentContact || {};
    const returnDateValue = returnDateFromDialog || returnDate;
    const formattedReturnDate = returnDateValue ? new Date(returnDateValue).toISOString().split('T')[0] : null;

    const classifyReviewEmailDto: ClassifyReviewEmailDto = {
      messageHandlerId: id,
      ruleId: newStatus.ruleId,
      messageId,
      sdrId,
      interactionId,
      sentDate,
      ...(formattedReturnDate && { returnDate: formattedReturnDate }),
      uid,
      sdr,
      contactId,
      ...(isBlacklisted && { addToBlacklist: isBlacklisted }),
      ...(isBlacklisted && { fromMail: fromMail }),
    };

    if (!Object.values(classifyReviewEmailDto).every((value) => value)) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }

    this.classifyEmailMatched(classifyReviewEmailDto);
  }

  private classifyEmailMatched(classifyReviewEmailDto: ClassifyReviewEmailDto) {
    this.loadingStateService.setLoadingState(true);

    if (this.isReview) {
      this.mailReviewService
        .classifyEmailMatched(classifyReviewEmailDto)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.loadingStateService.setLoadingState(false)),
        )
        .subscribe({
          next: (response) => {
            if (response?.status === ResponseStatus.Error) {
              const message = response?.message || 'An error occurred while trying to classify';
              this.snackBarService.showError(message);
              return;
            }
            this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
            this.interactionService.setInteractionsUpdated(true);
          },
          error: () => {
            this.snackBarService.showError(`An error occurred while trying to classify`);
          },
        });
    } else {
      this.classifierLogService
        .markEmailUpdateAsValid(this.selectedMessage.classifierLogId)
        .pipe(
          retry(1),
          takeUntil(this.destroy$),
          catchError((error) => {
            this.snackBarService.showError('Failed to mark as valid');
            return throwError(() => error);
          }),
          switchMap((response) => {
            if (response) {
              return this.mailReviewService.classifyEmailMatched(classifyReviewEmailDto);
            }
          }),
          catchError((error) => {
            this.snackBarService.showError('An error occurred while trying to classify');
            return throwError(() => error);
          }),
          finalize(() => this.loadingStateService.setLoadingState(false)),
        )
        .subscribe({
          next: (response) => {
            if (response?.status === ResponseStatus.Error) {
              const message = response?.message || 'An error occurred while trying to classify';
              this.snackBarService.showError(message);
              return;
            }
            this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
            this.interactionService.setInteractionsUpdated(true);
          },
        });
    }
  }

  private handleClassifyEmail(newStatus: ClassificationStatus) {
    const { id, uid, sdr, messageId } = this.selectedMessage?.email;
    const emailAddress = this.currentContact?.emailAddress || null;

    const messageToClassifyDto: MessageToClassifyDto = {
      currentFolder: newStatus.ruleDescription,
      destinationFolderId: newStatus?.destinationFolderId || 1,
      messageHandlerId: id,
      messageId,
      uid,
      sdr,
      contactEmail: emailAddress,
    };

    const { contactEmail, ...requiredFields } = messageToClassifyDto;

    if (!Object.values(requiredFields).every((value) => value)) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }
    this.classifyEmail(messageToClassifyDto);
  }

  private classifyEmail(messageToClassifyDto: MessageToClassifyDto) {
    this.loadingStateService.setLoadingState(true);

    if (this.isReview) {
      this.mailReviewService
        .classifyEmail(messageToClassifyDto)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.loadingStateService.setLoadingState(false)),
        )
        .subscribe({
          next: (response) => {
            if (response?.status === ResponseStatus.Error) {
              const message = response?.message || 'An error occurred while trying to classify';
              this.snackBarService.showError(message);
              return;
            }
            this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
            this.interactionService.setInteractionsUpdated(true);
          },
          error: () => {
            this.snackBarService.showError(`An error occurred while trying to classify`);
          },
        });
    } else {
      this.classifierLogService
        .markEmailUpdateAsValid(this.selectedMessage.classifierLogId)
        .pipe(
          retry(1),
          takeUntil(this.destroy$),
          catchError((error) => {
            this.snackBarService.showError('Failed to mark as valid');
            return throwError(() => error);
          }),
          switchMap((response) => {
            if (response) {
              return this.mailReviewService.classifyEmail(messageToClassifyDto);
            }
          }),
          catchError((error) => {
            this.snackBarService.showError('An error occurred while trying to classify');
            return throwError(() => error);
          }),
          finalize(() => this.loadingStateService.setLoadingState(false)),
        )
        .subscribe({
          next: (response) => {
            if (response?.status === ResponseStatus.Error) {
              const message = response?.message || 'An error occurred while trying to classify';
              this.snackBarService.showError(message);
              return;
            }
            this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
            this.interactionService.setInteractionsUpdated(true);
          },
        });
    }
  }

  toggleOverlay() {
    this.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
  }

  openOverlay() {
    if (this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.buttonRef.element)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ])
      .withPush(false)
      .withFlexibleDimensions(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());

    const templatePortal = new TemplatePortal(this.overlayContent, this.buttonRef);
    this.overlayRef.attach(templatePortal);
    this.isOverlayOpen = true;
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.isOverlayOpen = false;
    }
  }

  isButtonDisabledForReview(status: ClassificationStatus): boolean {
    const isPostConversionComplaint = status?.ruleDescription === this.classificationStatus.PostConversionComplaint;
    const isConverted = status?.ruleDescription === this.classificationStatus.Converted;
    const isConvertedChannel = status?.ruleDescription === this.classificationStatus.ConvertedChannel;
    const isSentInteractionRequired =
      status?.ruleDescription === this.classificationStatus.Forwarded ||
      status?.ruleDescription === this.classificationStatus.Replied ||
      status?.ruleDescription === this.classificationStatus.Register;

    return (
      !this.selectedInteraction ||
      (isPostConversionComplaint && !this.convertedInteraction && !this.convertedChannelInteraction) ||
      (isConverted && !!this.convertedChannelInteraction) ||
      (isConvertedChannel && !!this.convertedInteraction) ||
      (isConvertedChannel && this.clientId !== 4) ||
      (isSentInteractionRequired && this.selectedInteraction?.prospectStatus !== ClassificationStatusEnum.Sent) ||
      !this.selectedMessage
    );
  }

  isButtonDisabledForManual(status: ClassificationStatus): boolean {
    const isSpecialStatus =
      status?.ruleDescription === ClassificationStatusEnum.DeleteFrom ||
      status?.ruleDescription === ClassificationStatusEnum.Other ||
      status?.ruleDescription === ClassificationStatusEnum.Inbox;

    if (isSpecialStatus) {
      return false;
    }

    const isClosedCompanyOrAbuseReport =
      status?.ruleDescription === ClassificationStatusEnum.ClosedCompany ||
      status?.ruleDescription === ClassificationStatusEnum.AbuseReportCompany;
    const isConvertedOrSent =
      this.selectedInteraction?.prospectStatus === 'Converted - Lead' ||
      this.selectedInteraction?.prospectStatus === 'Sent';
    const isForwarded = status?.ruleDescription === this.classificationStatus.Forwarded;

    const isInteractionToday = this.isInteractionFromToday(this.selectedInteraction?.utcDatetimeCreated);

    return (
      !this.selectedInteraction ||
      (isClosedCompanyOrAbuseReport && (isConvertedOrSent || !isInteractionToday)) ||
      (isForwarded && this.selectedInteraction?.prospectStatus !== ClassificationStatusEnum.Sent)
    );
  }

  private isInteractionFromToday(interactionDate?: string): boolean {
    if (!interactionDate) return false;

    const today = new Date();
    const interaction = new Date(interactionDate);

    return (
      interaction.getFullYear() === today.getFullYear() &&
      interaction.getMonth() === today.getMonth() &&
      interaction.getDate() === today.getDate()
    );
  }
}
