import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { GetLocationDto } from '../../../../model/catalog/get-location.dto';
import { Location } from '../../../../model/catalog/location.model';
import { ContactType } from '../../../../model/common/contact-type.enum';
import { Pattern } from '../../../../model/configuration/pattern.model';
import { Lead } from '../../../../model/lead/lead.model';
import { CatalogService } from '../../../../services/catalog.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { LeadService } from '../../../../services/lead.service';
import { SnackBarService } from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() addContact: EventEmitter<Lead> = new EventEmitter();

  contactForm: FormGroup;
  contactTypes = ContactType;
  countries: Location[] = [];
  states: Location[] = [];
  cities: Location[] = [];
  adminArea1: Location[] = [];
  adminArea2: Location[] = [];
  patterns: Pattern[] = [];
  contactAlreadyExist = false;
  filteredCountries: Observable<Location[]>;
  filteredStates: Observable<Location[]>;
  filteredCities: Observable<Location[]>;

  private destroy$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private catalogService: CatalogService,
    private configurationService: ConfigurationService,
    private leadService: LeadService,
    private snackBarService: SnackBarService,
  ) {
    this.createForm();
    this.getCountries();
    this.getPatterns();
  }

  ngOnInit(): void {
    this.contactForm
      .get('linkedInUrl')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value) => {
        if (value) {
          const formattedValue = `/${value.replace(/^\/|\/$/g, '')}`;

          if (formattedValue !== value) {
            this.contactForm.get('linkedInUrl').setValue(formattedValue, { emitEvent: false });
          }
        }

        this.updateOrigFullNameValidators();
      });

    this.leadService.saveCalled$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.createContact();
    });

    this.contactForm.statusChanges.subscribe((status) => {
      const isValid = status === 'VALID' && !this.contactAlreadyExist;
      this.leadService.setIsSaveValid(isValid);
    });
  }

  ngAfterViewInit(): void {
    this.contactForm.get('country').valueChanges.subscribe(() => {
      this.contactForm.get('adminArea1').reset();
      this.contactForm.get('adminArea2').reset();
      this.getStates();
    });
    this.contactForm.get('adminArea1').valueChanges.subscribe(() => {
      this.contactForm.get('adminArea2').reset();
      this.getCities();
    });

    this.contactForm
      .get('emailAddress')
      .valueChanges.pipe(
        debounceTime(1000),
        map((email) => email?.trim()?.toLowerCase()),
        tap((email) => {
          this.contactForm.get('emailAddress').setValue(email, { emitEvent: false });
        }),
        filter((email) => !!email && this.contactForm.get('emailAddress').valid),
      )
      .subscribe((email) => {
        this.validateCorporateEmail(email);
        this.validateExistingContact(email);
      });

    this.startFilteringCatalogs();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get contactTypeOptions() {
    return Object.keys(this.contactTypes).filter((key) => !isNaN(Number(key)));
  }

  get isValid(): boolean {
    return this.contactForm.valid;
  }

  startFilteringCatalogs() {
    this.filteredCountries = this.contactForm.get('country').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterCountries(name as string) : this.countries.slice();
      }),
    );

    this.filteredStates = this.contactForm.get('adminArea1').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterStates(name as string) : this.states.slice();
      }),
    );

    this.filteredCities = this.contactForm.get('adminArea2').valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterCities(name as string) : this.cities.slice();
      }),
    );
  }

  displayCountryFn(country: Location): string {
    return country && country.name ? country.name : '';
  }

  displayStateFn(state: Location): string {
    return state && state.name ? state.name : '';
  }

  displayCityFn(city: Location): string {
    return city && city.name ? city.name : '';
  }

  createContact() {
    if (this.contactForm.valid) {
      const contactTypeId = parseInt(this.contactForm.get('contactTypeId')?.value);
      const { country, adminArea1, adminArea2, companyName, ...others } = this.contactForm.getRawValue();
      this.addContact.emit({
        ...others,
        contactTypeId,
        country: country.name,
        adminArea1: adminArea1?.name || null,
        adminArea2: adminArea2?.name || null,
      });
    }
  }

  createForm() {
    this.contactForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$')]],
      lnCompanyId: [{ value: null, disabled: true }],
      linkedInCompanyUrl: [{ value: '', disabled: true }],
      companyName: [{ value: '', disabled: true }],
      contactTypeId: [null, Validators.required],
      linkedInUrl: ['', Validators.pattern(/^\/in\/[\p{L}0-9_-]+$/u)],
      origFullName: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      jobTitle: ['', Validators.required],
      country: [null, Validators.required],
      adminArea1: [null],
      adminArea2: [null],
      patternId: [null, Validators.required],
      phones: [
        '',
        Validators.pattern(
          /^(\+?\d{1,4}[\s.-]?)?(0?\d{1,4}[\s.-]?)?\(?\d{1,}\)?\s?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}( x\d{1,5})?(, (\+?\d{1,4}[\s.-]?)?(0?\d{1,4}[\s.-]?)?\(?\d{1,}\)?\s?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}( x\d{1,5})?)*$/,
        ),
      ],
    });
  }

  getCountries() {
    this.contactForm.get('adminArea1').disable();
    this.contactForm.get('adminArea2').disable();

    const locationDto: GetLocationDto = {};
    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.countries = locations || [];
          if (this.countries.length) {
            this.contactForm.get('country').enable();
          } else {
            this.contactForm.get('country').disable();
          }
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting countries');
        },
      });
  }

  getStates() {
    const countryId = this.contactForm.get('country')?.value?.id;

    if (!countryId) return;

    this.contactForm.get('adminArea2').disable();
    const locationDto: GetLocationDto = {
      countryId,
    };

    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.states = locations || [];
          this.states.length
            ? this.contactForm.get('adminArea1').enable()
            : this.contactForm.get('adminArea1').disable();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting states');
        },
      });
  }

  getCities() {
    const adminArea01 = this.contactForm.get('adminArea1')?.value?.id;

    if (!adminArea01) return;

    const locationDto: GetLocationDto = {
      adminArea01,
    };
    this.catalogService
      .getLocations(locationDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (locations) => {
          this.cities = locations || [];
          this.cities.length
            ? this.contactForm.get('adminArea2').enable()
            : this.contactForm.get('adminArea2').disable();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting cities');
        },
      });
  }

  getPatterns() {
    this.configurationService
      .getPatterns()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (patterns) => {
          this.patterns = patterns || [];
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting patterns');
        },
      });
  }

  updateOrigFullNameValidators() {
    const linkedInUrl = this.contactForm.get('linkedInUrl');
    const origFullName = this.contactForm.get('origFullName');

    linkedInUrl.value && linkedInUrl.valid
      ? origFullName.setValidators([Validators.required])
      : origFullName.clearValidators();

    origFullName.updateValueAndValidity();
  }

  validateExistingContact(emailAddress: string) {
    this.leadService
      .getLeadByEmail(emailAddress)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.contactForm.updateValueAndValidity()),
      )
      .subscribe({
        next: (lead) => {
          if (lead) {
            this.contactAlreadyExist = true;
            this.snackBarService.showError('There is already a contact with this email');
          } else {
            this.contactAlreadyExist = false;
          }
        },
        error: () => {
          this.contactAlreadyExist = false;
          this.snackBarService.showError('An error occurred while validating existing contact');
        },
      });
  }

  validateCorporateEmail(email: string) {
    this.leadService
      .validateCorporateEmail(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (corporateCompany) => {
          this.contactForm.get('companyName').setValue(corporateCompany?.companyName || '');
          this.contactForm.get('lnCompanyId').setValue(corporateCompany.companyId);
          this.contactForm.get('linkedInCompanyUrl').setValue(corporateCompany?.linkedInUrl || '');
          this.contactForm
            .get('contactTypeId')
            .setValue(
              corporateCompany.companyId === 0
                ? ContactType.Personal.toString()
                : corporateCompany.companyId > 0
                  ? ContactType.Corporate.toString()
                  : '',
            );
          corporateCompany
            ? this.contactForm.get('contactTypeId').disable()
            : this.contactForm.get('contactTypeId').enable();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while validating corporate email');
        },
      });
  }

  private _filterCountries(value: string): Location[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  private _filterStates(value: string): Location[] {
    const filterValue = value.toLowerCase();

    return this.states.filter((option) => option.name.toLowerCase().includes(filterValue));
  }

  private _filterCities(value: string): Location[] {
    const filterValue = value.toLowerCase();

    return this.cities.filter((option) => option.name.toLowerCase().includes(filterValue));
  }
}
