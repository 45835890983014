<div
  class="extra-info"
  *ngIf="selectedConversation"
>
  <div class="extra-info__container">
    <p class="extra-info__item">
      <span class="title">SDR ID:</span>{{ sdrConversation?.sdrId }} <span class="title">SDR Name:</span
      >{{ sdrConversation?.sdrName }}
    </p>
    <p
      class="extra-info__item"
      *ngFor="let url of selectedConversation?.encryptedUrls"
    >
      <a
        [href]="url"
        target="_blank"
        >{{ url }}</a
      >
    </p>
    <p class="extra-info__item">
      <span class="title">Conversation Id: </span>{{ selectedConversation.conversationId }}
    </p>
  </div>
</div>
