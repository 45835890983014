<section
  class="client-contact-history mat-elevation-z8"
  tabindex="0"
>
  <table
    mat-table
    [dataSource]="clientContactHistoryData"
  >
    <ng-container matColumnDef="contactId">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Contact ID
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.contactId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="interactionId">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Interaction ID
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.interactionId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Created Date
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.createdDate | date: 'YYYY-MM-dd h:mm a' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sdr">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        SDR
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.sdr }}
      </td>
    </ng-container>

    <ng-container matColumnDef="salesRep">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Sales Rep
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.salesRep }}
      </td>
    </ng-container>

    <ng-container matColumnDef="geographicZone">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Geographic Zone
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.geographicZone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="language">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Language
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.language }}
      </td>
    </ng-container>

    <ng-container matColumnDef="campaignId">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Campaign ID
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.campaignId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="templateId">
      <th
        mat-header-cell
        *matHeaderCellDef
      >
        Template ID
      </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        {{ element.templateId }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</section>
