import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { SalesRepresentative } from "../model/SalesRepresentative";
import { Template } from "../model/TemplateData";


@Injectable({
    providedIn: 'root'
  })
export class TemplateService {

    apiBackEnd = '';
    constructor(private http: HttpClient) {
      this.apiBackEnd = environment.apiBaseURL;
      }

    public getUseTemplate(templateTypeId: number, sdr: number): Observable<Template[]> {
        return this.http.get(`${environment.newApiBaseUrl}/template/get_use_template/${templateTypeId}/${sdr}`).pipe(map((response: any) => {
          return response.data.map((template)=> new Template(template));
        }));
    }

    public getTemplateTypes(): Observable<any> {
        return this.http.get(`${this.apiBackEnd}/app/template_type/types`);
    }

    public getSalesRepresentativesByClient(sdrId: number): Observable<SalesRepresentative[]> {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("sdrId", sdrId.toString());
      return this.http.get(`${environment.newApiBaseUrl}/configuration/client_contact/get_client_contact_not_suspended/${sdrId}`).pipe(map((response: any) => {
        return response.data.map((salesRep)=> { 
          return new SalesRepresentative({ 
            name: salesRep.name ? salesRep.name : salesRep.email,
            profileCC: salesRep.profileCC,
            geographicZone: salesRep.geographic_zone
          }) 
        });
      }));
    }

    public getTemplates(templateTypeId: number, limit: number, offset: number,
                        searchText: string, orderBy: string, orderType: string): Observable<any> {
      const url = `${environment.newApiBaseUrl}/template/search_template/${templateTypeId}/${limit}/${offset}/${orderBy}/${orderType}`;
      let queryParams = new HttpParams();
      queryParams = queryParams
                    .append("search_text", searchText != undefined ? searchText : "");
      return this.http.get(url, { params: queryParams }).pipe(map((response: any) => {
        if(response.data) {
          response.data = response.data.templates.map(template => new Template(template));
        }
        return response;
      }));
    }
}
