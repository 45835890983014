import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { AbstractControl, FormControl, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { TemplateService } from 'src/app/services/template.service';
import { SalesRepresentative } from 'src/app/model/SalesRepresentative';

@Component({
  selector: 'app-select-sales-representative',
  templateUrl: './select-sales-representative.component.html',
  styleUrls: ['./select-sales-representative.component.scss'],
})
export class SelectSalesRepresentativeComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  public isLoading = true;
  public salesRepFilterControl = new FormControl();
  filteredSalesRepOptions: Observable<any[]>;
  allSalesRepObj: SalesRepresentative[] = [];
  availableSalesReps: string[] = [];

  constructor(
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialogRef<SelectSalesRepresentativeComponent>,
    public sdApiService: SdApiService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.templateService.getSalesRepresentativesByClient(this.data.sdrId).subscribe((salesReps) => {
      this.isLoading = false;
      if(this.data.source === 'send-message'){
        this.allSalesRepObj = salesReps.filter(x => x.geographicZone === 'LinkedIn');
      }else{
        this.allSalesRepObj = salesReps;
      }
      

      this.salesRepFilterControl.addValidators((): ValidationErrors | null => {
        return !this.getSaleRepProfileCC() ? { invalidProfileCC: true } : null;
      });

      this.filteredSalesRepOptions = this.salesRepFilterControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filterSalesRep(name) : this.allSalesRepObj.slice().sort(this.sortSalesRep))),
      );
    });
  }

  get isValidSaleRep(): boolean {
    return this.salesRepFilterControl.valid;
  }

  private _filterSalesRep(salesRep: string): SalesRepresentative[] {
    const filterValue = salesRep.toLowerCase();
    return this.allSalesRepObj
      .filter((salesRep) => salesRep.name.toLowerCase().includes(filterValue))
      .sort(this.sortSalesRep);
  }

  displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  getSaleRepProfileCC(): SalesRepresentative | null {
    if (!this.salesRepFilterControl.value) return;
    return this.allSalesRepObj.find((salesRep) => salesRep.name === this.salesRepFilterControl.value);
  }

  useSalesRep() {
    this.dialog.close(this.getSaleRepProfileCC());
  }

  sortSalesRep(a, b) {
    const name1 = a.name.toLowerCase().trim();
    const name2 = b.name.toLowerCase().trim();
    if (name1 > name2) {
      return 1;
    }
    if (name1 < name2) {
      return -1;
    }
    return 0;
  }
}
