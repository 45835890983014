import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ComposerMailService } from 'src/app/services/composer-mail.service';
import { ComposeEmail } from 'src/app/model/ComposeEmail';
import { SdApiService } from 'src/app/services/base/sd-api.service';
import { UntypedFormControl } from '@angular/forms';
import { forkJoin, merge, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, take, tap } from 'rxjs/operators';
import { ComposeEmailCategory } from 'src/app/model/ComposeEmailCategory';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Sdr } from 'src/app/model/Sdr';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';
import { AppConstants } from 'src/app/resources/app-constants';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { FeedService } from 'src/app/services/feed.service';
import { FOLDERS } from 'src/app/model/Email';
declare var $: any;

@Component({
  selector: 'app-recipient-fields',
  templateUrl: './recipient-fields.component.html',
  styleUrls: ['./recipient-fields.component.scss'],
})
export class RecipientFieldsComponent implements OnInit, OnDestroy {
  separatorKeyCodes = [ENTER, COMMA];
  public toEmailList = [];
  public ccEmailList = [];
  public bccEmailList = [];
  sdrs: string[];
  allSdrs: Sdr[];
  subject: string;
  removable = true;
  blockedSdrs: string[];
  currentComposedEmail: ComposeEmail;
  fromControl = new UntypedFormControl('', [this.forbiddenNameValidator()]);
  filteredSdrs: Observable<string[]>;
  toControl = new UntypedFormControl('', []);
  ccControl = new UntypedFormControl('', []);
  bccControl = new UntypedFormControl('', []);
  filteredAddresses: Observable<string[]>;
  filteredAddressesCC: Observable<string[]>;
  filteredAddressesBCC: Observable<string[]>;
  clientAddresses: string[];
  @ViewChild('toAuto') toAutocomplete: MatAutocomplete;
  @ViewChild('ccAuto') ccAutocomplete: MatAutocomplete;
  @ViewChild('bccAuto') bccAutocomplete: MatAutocomplete;
  @ViewChild('toInput') toInput: ElementRef;
  @ViewChild('ccInput') ccInput: ElementRef;
  @ViewChild('bccInput') bccInput: ElementRef;

  clients = [];
  clientSubscription: Subscription;
  sdrSubscription: Subscription;
  blockedSDRsSubscription: Subscription;

  constructor(
    private composerMailService: ComposerMailService,
    private sdApiService: SdApiService,
    public messageAttachmentService: MessageAttachmentService,
    private dialog: MatDialog,
    private feedService: FeedService,
  ) {}

  ngOnInit() {
    this.composerMailService.composedEmail.subscribe((composedEmail) => {
      if (composedEmail) {
        this.currentComposedEmail = composedEmail;
        const refreshedToEmailList = [];
        composedEmail.to.forEach((ceTo) => {
          refreshedToEmailList.push({ value: ceTo, invalid: !this.validateEmail(ceTo) });
        });
        this.toEmailList = refreshedToEmailList;
        const refreshedCCEmailList = [];
        composedEmail.cc.forEach((ceCC) => {
          refreshedCCEmailList.push({ value: ceCC, invalid: !this.validateEmail(ceCC) });
        });
        this.ccEmailList = refreshedCCEmailList;
        const refreshedBCCEmailList = [];
        composedEmail.bcc.forEach((ceBCC) => {
          refreshedBCCEmailList.push({ value: ceBCC, invalid: !this.validateEmail(ceBCC) });
        });
        this.bccEmailList = refreshedBCCEmailList;
        this.subject = composedEmail.subject;
        if (
          composedEmail.category != ComposeEmailCategory.New &&
          composedEmail.category != ComposeEmailCategory.Resend
        ) {
          this.fromControl.disable();
        } else {
          this.fromControl.enable();
        }
        this.fromControl.setValue(composedEmail.from);
        this.loadClientsSdrsData(composedEmail);
      } else {
        this.toEmailList = [];
        this.ccEmailList = [];
        this.bccEmailList = [];
        this.subject = '';
        this.fromControl.enable();
        this.fromControl.setValue('');
      }
    });

    this.filteredSdrs = this.fromControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(500),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.sdrs?.slice())),
      tap(() => this.updateFrom()),
    );
    // Have it listen to fromControl change as well
    this.filteredAddresses = merge(
      this.toControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => this._filterAddresses(name)),
      ),
      this.fromControl.valueChanges.pipe(map(() => this._filterAddresses(this.toControl.value))),
    );

    this.filteredAddressesCC = merge(
      this.ccControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => this._filterAddresses(name)),
      ),
      this.fromControl.valueChanges.pipe(map(() => this._filterAddresses(this.ccControl.value))),
    );

    this.filteredAddressesBCC = merge(
      this.bccControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => this._filterAddresses(name)),
      ),
      this.fromControl.valueChanges.pipe(map(() => this._filterAddresses(this.bccControl.value))),
    );

    if (!this.blockedSDRsSubscription) {
      this.blockedSDRsSubscription = this.sdApiService.blockedSdrs.subscribe((blockedSdrs) => {
        this.blockedSdrs = blockedSdrs;
      });
    }
  }

  loadClientsSdrsData(composedEmail) {
    this.feedService.selectedFolder.pipe(take(1)).subscribe((folder) => {
      if (!this.clientSubscription) {
        const obs =
          folder === FOLDERS.REVIEW
            ? this.sdApiService.userClientsReview
            : folder === FOLDERS.SENT
              ? this.sdApiService.userClientsSent
              : folder === FOLDERS.SALE_OPERATORS
                ? this.sdApiService.userClientsSales
                : this.sdApiService.userClients;

        this.clientSubscription = obs.subscribe((clients: any[]) => {
          this.mapClientsData(clients);
        });
      }

      if (!this.sdrSubscription) {
        forkJoin([
          this.sdApiService.userSdrsReview.pipe(take(1)),
          this.sdApiService.userSdrsSent.pipe(take(1)),
          this.sdApiService.userSdrsSales.pipe(take(1)),
          this.sdApiService.userSDRs.pipe(take(1)),
        ]).subscribe((results) => {
          const unfilteredSdrs = results.flat();
          this.allSdrs = unfilteredSdrs.filter((sdr, index, self) => self.findIndex((t) => t.id === sdr.id) === index);
          this.mapSdrsData(this.allSdrs, composedEmail);
        });
      }
    });
  }

  mapClientsData(clients) {
    this.clients = clients;
    this.clientAddresses = Array.from(
      new Set(
        [].concat.apply(
          [],
          clients.map((c) => c.contactEmails),
        ),
      ),
    );
    this.clientAddresses = this.clientAddresses.sort();
  }

  mapSdrsData(sdrs, composedEmail) {
    this.sdrs = sdrs.map((e) => e.email).sort();
    this.allSdrs = sdrs;
    if (composedEmail.category != ComposeEmailCategory.New) {
      const currentClient = this.allSdrs.filter((e) => e.email == this.fromControl.value)[0]?.client;
      this.clientAddresses = this.clients.filter((e) => e.name == currentClient)[0]?.contactEmails?.sort();
    }
  }

  ngOnDestroy() {
    this.clientSubscription.unsubscribe();
    this.clientSubscription = undefined;
    this.sdrSubscription?.unsubscribe();
    this.sdrSubscription = undefined;
    this.blockedSDRsSubscription.unsubscribe();
    this.blockedSDRsSubscription = undefined;
  }

  addToEmail(event): void {
    if (!this.toAutocomplete.isOpen) {
      event.value = event.value.trim();
      if (event.value) {
        if (this.validateEmail(event.value)) {
          this.toEmailList.push({ value: event.value, invalid: false });
        } else {
          this.toEmailList.push({ value: event.value, invalid: true });
        }
      }
      if (event.input) {
        event.input.value = '';
      }
      this.updateToEmail();
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeToEmail(data: any): void {
    if (this.toEmailList.indexOf(data) >= 0) {
      this.toEmailList.splice(this.toEmailList.indexOf(data), 1);
    }
    this.updateToEmail();
  }

  updateToEmail() {
    this.currentComposedEmail.to = this.toEmailList.map((to) => to.value);
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  addCCEmail(event): void {
    if (!this.ccAutocomplete.isOpen) {
      event.value = event.value.trim();
      if (event.value) {
        if (this.validateEmail(event.value)) {
          this.ccEmailList.push({ value: event.value, invalid: false });
        } else {
          this.ccEmailList.push({ value: event.value, invalid: true });
        }
      }
      if (event.input) {
        event.input.value = '';
      }
      this.updateCCEmail();
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeCCEmail(data: any): void {
    if (this.ccEmailList.indexOf(data) >= 0) {
      this.ccEmailList.splice(this.ccEmailList.indexOf(data), 1);
    }
    this.updateCCEmail();
  }

  updateCCEmail() {
    this.currentComposedEmail.cc = this.ccEmailList.map((cc) => cc.value);
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  addBCCEmail(event): void {
    if (!this.bccAutocomplete.isOpen) {
      event.value = event.value.trim();
      if (event.value) {
        if (this.validateEmail(event.value)) {
          this.bccEmailList.push({ value: event.value, invalid: false });
        } else {
          this.bccEmailList.push({ value: event.value, invalid: true });
        }
      }
      if (event.input) {
        event.input.value = '';
      }
      this.updateBccEmail();
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeBCCEmail(data: any): void {
    if (this.bccEmailList.indexOf(data) >= 0) {
      this.bccEmailList.splice(this.bccEmailList.indexOf(data), 1);
    }
    this.updateBccEmail();
  }

  updateBccEmail() {
    const bccEmailList = [];
    this.bccEmailList.forEach((bcc) => {
      bccEmailList.push(bcc.value);
    });
    this.currentComposedEmail.bcc = bccEmailList;
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  private validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  updateSubject(event): void {
    this.currentComposedEmail.subject = event.target.value;
    this.currentComposedEmail.currentEdition = (window as any).editorHtml;
    this.composerMailService.setComposedEmail(this.currentComposedEmail);
  }

  displayFn(sdr: string): string {
    return sdr ? sdr : '';
  }

  displayAddressFn(address: string): string {
    return address ? address : '';
  }

  private _filter(sdr: string): string[] {
    const filterValue = sdr.toLowerCase();

    return this.sdrs?.filter((e) => e?.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAddresses(address: string): string[] {
    const filterValue = address.toLowerCase();
    let addressValues = this.clientAddresses;
    if (this.fromControl.value != '' && this.allSdrs.filter((e) => e.email == this.fromControl.value)?.length > 0) {
      const currentClient = this.allSdrs?.filter((e) => e.email == this.fromControl.value)[0]?.client;
      addressValues = this.clients?.filter((e) => e.name == currentClient)[0]?.contactEmails?.sort();
    }
    return addressValues?.filter((e) => e?.toLowerCase()?.indexOf(filterValue) === 0);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  generateSignatureHTML = (signature) => {
    return `
      <div id="email-signature" contenteditable="false" style="padding: 0 10px;">
        ${signature}
      </div>`;
  };

  updateFrom() {
    if (
      this.currentComposedEmail.category !== ComposeEmailCategory.Reply &&
      this.currentComposedEmail.category !== ComposeEmailCategory.Forward
    ) {
      const newSignatureObj = this.allSdrs.find((e) => e.email === this.fromControl.value);
      const newSignature =
        newSignatureObj && newSignatureObj?.signature?.trim().length > 0 ? newSignatureObj.signature : '';

      let currentContent = (window as any).editorHtml || '<p><br></p>';
      const parser = new DOMParser();
      const doc = parser.parseFromString(currentContent, 'text/html');

      const signatureDivs = doc.querySelectorAll('div#email-signature');
      signatureDivs?.forEach((div) => div.remove());

      currentContent = doc.body.innerHTML;

      if (newSignature) {
        const newSignatureHTML = this.generateSignatureHTML(newSignature);
        currentContent += newSignatureHTML;
      }

      this.currentComposedEmail.currentEdition = currentContent;
      (window as any).editorHtml = this.currentComposedEmail.currentEdition;
    }
    // Set from
    this.currentComposedEmail.from = this.fromControl.value;
    this.currentComposedEmail.fromSdrId =
      this.allSdrs.filter((sdr) => sdr.email === this.fromControl.value).length > 0
        ? this.allSdrs.find((sdr) => sdr.email === this.fromControl.value).id
        : '';
    this.currentComposedEmail.sender =
      this.allSdrs.filter((e) => e.email == this.fromControl.value).length > 0
        ? this.allSdrs.filter((e) => e.email == this.fromControl.value)[0].imap
        : 'gmail';
    this.composerMailService.setComposedEmail(this.currentComposedEmail);

    if (this.currentComposedEmail.sender == 'gmail') {
      $('.note-editable').css('font-size', AppConstants.GMAIL_FONT_SIZE + AppConstants.GMAIL_FONT_UNIT);
      $('.note-editable').css('font-family', AppConstants.GMAIL_FONT_FAMILY);
    } else {
      $('.note-editable').css('font-size', AppConstants.OUTLOOK_FONT_SIZE + AppConstants.OUTLOOK_FONT_UNIT);
      $('.note-editable').css('font-family', AppConstants.OUTLOOK_FONT_FAMILY);
    }

    const attachError = this.messageAttachmentService.checkAttachmentSize(
      this.currentComposedEmail.attachments,
      this.currentComposedEmail.sender,
    );
    if (attachError !== '') {
      const errorConfirmDialogConfig = new MatDialogConfig();
      errorConfirmDialogConfig.data = {
        title: 'Attachment Errors',
        message: attachError,
      };
      this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
    }
  }

  forbiddenNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.sdrs) {
        const forbidden = !this.sdrs.includes(control.value);
        return forbidden ? { forbiddenName: { value: control.value } } : null;
      }
    };
  }

  updateToAuto(): void {
    const enteredEmail = this.toControl.value;
    if (enteredEmail) {
      if (this.validateEmail(enteredEmail)) {
        this.toEmailList.push({ value: enteredEmail, invalid: false });
      } else {
        this.toEmailList.push({ value: enteredEmail, invalid: true });
      }
      this.toControl.setValue('');
      this.toInput.nativeElement.value = '';
    }
    this.updateToEmail();
  }

  updateCCAuto(): void {
    const enteredEmail = this.ccControl.value;
    if (enteredEmail) {
      if (this.validateEmail(enteredEmail)) {
        this.ccEmailList.push({ value: enteredEmail, invalid: false });
      } else {
        this.ccEmailList.push({ value: enteredEmail, invalid: true });
      }
      this.ccControl.setValue('');
      this.ccInput.nativeElement.value = '';
    }
    this.updateCCEmail();
  }

  updateBCCAuto(): void {
    const enteredEmail = this.bccControl.value;
    if (enteredEmail) {
      if (this.validateEmail(enteredEmail)) {
        this.bccEmailList.push({ value: enteredEmail, invalid: false });
      } else {
        this.bccEmailList.push({ value: enteredEmail, invalid: true });
      }
      this.bccControl.setValue('');
      this.bccInput.nativeElement.value = '';
    }
    this.updateBccEmail();
  }

  get isSdrBlocked(): boolean {
    return this.blockedSdrs.some((sdr) => sdr === this.fromControl.value);
  }

  get isSendConfigured(): boolean {
    const selectedSdr = this.allSdrs.find((sdr) => sdr.email === this.fromControl.value);
    if (selectedSdr) {
      return selectedSdr.isSendConfigured;
    }
    return true;
  }
}
