import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company } from '../../../model/common/company.model';
import { Contact } from '../../../model/common/contact.model';
import { GenericDataMapping, ValueType } from '../../../model/common/generic-map-data.model';
import { Interaction } from '../../../model/common/interaction.model';
import { ClientRepresentative } from '../../../model/mail-classifier/client-representative.model';
import { UpdateLeadInfoDto } from '../../../model/mail-classifier/update-lead-info.dto';
import { ConfigurationService } from '../../../services/configuration.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-interaction-item',
  templateUrl: './interaction-item.component.html',
  styleUrls: ['./interaction-item.component.scss'],
})
export class InteractionItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() interaction: Interaction;
  @Input() contact: Contact;
  @Input() company: Company;
  phoneCtrl = new FormControl('', [
    Validators.pattern(
      /^(\+?\d{1,4}[\s.-]?)?(0?\d{1,4}[\s.-]?)?\(?\d{1,}\)?\s?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}( x\d{1,5})?(, (\+?\d{1,4}[\s.-]?)?(0?\d{1,4}[\s.-]?)?\(?\d{1,}\)?\s?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}( x\d{1,5})?)*$/,
    ),
  ]);
  contactRows: GenericDataMapping[] = [];
  interactionRows: GenericDataMapping[] = [];
  clientRepresentatives: ClientRepresentative = null;
  valueType = ValueType;
  isMobileOrTablet = false;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private snackBarService: SnackBarService,
    private mailReviewService: MailReviewService,
    private configurationService: ConfigurationService,
  ) {
    inject(BreakpointObserver)
      .observe(['(max-width: 959px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          this.isMobileOrTablet = result.breakpoints[query];
        }
      });
  }

  ngOnInit(): void {
    this.configurationService.clientRepresentatives$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (clientRepresentatives) => {
        this.clientRepresentatives = clientRepresentatives;
        this.setInteractionRows();
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contact']) {
      this.setContactRows();
    }

    if (changes['interaction']) {
      this.setInteractionRows();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setContactRows() {
    this.contactRows = [
      this.isMobileOrTablet && { title: 'Forwarder ID', value: this.interaction.forwardedContactId },
      this.isMobileOrTablet && { title: 'Campaing ID', value: this.interaction.campaignId },
      this.isMobileOrTablet && { title: 'Template ID', value: this.interaction.templateId },
      { title: 'Contact ID', value: this.contact.contactId },
      { title: 'Name', value: this.contact.fullName },
      { title: 'Title', value: this.contact.jobTitle },
      { title: 'Country', value: this.contact.country },
      { title: 'State', value: this.contact.adminArea1 },
      { title: 'City', value: this.contact.adminArea2 },
      { title: 'Region', value: this.contact?.region },
      { title: 'Responsibility', value: this.contact?.responsibility },
      { title: 'Department', value: this.contact?.department },
      {
        title: 'In Lead',
        value: this.contact.linkedInUrl ? `https://linkedin.com${this.contact.linkedInUrl}` : '',
        type: this.contact.linkedInUrl ? ValueType.Url : this.valueType.Text,
      },
      { title: 'Phones', value: this.contact?.phones },
    ];

    if (this.contact?.phones) {
      this.phoneCtrl.setValue(this.contact.phones);
    }
  }

  setInteractionRows() {
    this.interactionRows = [
      { title: 'Company', value: this.company?.companyName },
      {
        title: 'In Company',
        value: this.company?.linkedInUrl ? this.company?.linkedInUrl : '',
        type: this.company?.linkedInUrl ? ValueType.Url : this.valueType.Text,
      },
      { title: 'Country', value: this.company?.headquarters?.country },
      { title: 'Industry', value: this.company?.industry },
      { title: 'Size', value: this.company?.companySize },
      { title: 'Employees', value: this.company?.employeeCount },
      { title: 'Interaction ID', value: this.interaction?.interactionId },
      { title: 'Message ID', value: this.interaction?.messageId },
      { title: 'Sales Rep', value: this.interaction?.salesRepId || '' },
      {
        title: 'Email',
        value:
          this.clientRepresentatives?.representatives?.find((r) => r?.clientContactId === this.interaction?.salesRepId)
            ?.email || '',
      },
      {
        title: 'Template ID',
        value: this.interaction?.templateId,
      },
      { title: 'Source', value: this.interaction?.interactionSource },
    ];
  }

  handleUpdateLead() {
    if (!this.phoneCtrl.value || this.phoneCtrl.invalid) return;

    const {
      firstName: interactionFirstName,
      lastName: interactionLastName,
      jobTitle: interactionJobTitle,
    } = this.interaction;
    const {
      contactId,
      country,
      firstName: contactFirstName,
      lastName: contactLastName,
      jobTitle: contactJobTitle,
    } = this.contact;
    const {} = this.contact;
    const updateLeadInfoDto: UpdateLeadInfoDto = {
      firstName: interactionFirstName || contactFirstName,
      lastName: interactionLastName || contactLastName,
      jobTitle: interactionJobTitle || contactJobTitle,
      country,
      phone: this.phoneCtrl.value,
    };

    if (!Object.values(updateLeadInfoDto).every((value) => value) || !contactId) {
      this.snackBarService.showError('An error occurred while retrieving the information required to update the lead');
      return;
    }

    this.updateLead(contactId, updateLeadInfoDto);
  }

  updateLead(contactId: number, updateLeadInfoDto: UpdateLeadInfoDto) {
    this.mailReviewService
      .updateLeadInfo(contactId, updateLeadInfoDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Contact information was updated successfully');
        },
        error: () => this.snackBarService.showError('An error occurred while updating contact information'),
      });
  }
}
