import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Subject } from 'rxjs';
import { catchError, finalize, retry, switchMap, takeUntil } from 'rxjs/operators';
import { Interaction } from '../../../model/common/interaction.model';
import { StatusScreen } from '../../../model/common/status-screen.enum';
import { CompleteCaseDto } from '../../../model/complete-case-dialog/complete-case.dto';
import { ClassifierMessageDto } from '../../../model/mail-classifier/classifier-message.dto';
import { ClassifierMessage, ClassifierMessageResult } from '../../../model/mail-classifier/classifier-message.model';
import { LoadingStateService } from '../../../services/base/loading-state.service';
import { SdApiService } from '../../../services/base/sd-api.service';
import { ClassifierLogService } from '../../../services/classifier-log.service';
import { ComposerMailService } from '../../../services/composer-mail.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { MailComposerMinComponent } from '../../home/mail-composer-min/mail-composer-min.component';
import { CompleteCaseDialogComponent } from '../complete-case-dialog/complete-case-dialog.component';

@Component({
  selector: 'app-mail-classifier',
  templateUrl: './mail-classifier.component.html',
  styleUrls: ['./mail-classifier.component.scss'],
})
export class MailClassifierComponent implements OnInit, AfterViewInit, OnDestroy {
  classifierMessages: ClassifierMessage[] = [];
  currentMessage: ClassifierMessage = null;
  currentMessageIdx = 0;
  totalCount = 0;
  pendingCount = 0;
  completedCases = 0;
  isLoading = true;
  classifierMessageDto: ClassifierMessageDto = {
    statusScreen: StatusScreen.Review,
    page: 1,
    pageSize: 5,
  };
  selectedInteraction: Interaction = null;
  isReview = false;
  selectedMessage: ClassifierMessage = null;
  navigationButtonsDisable = false;
  private destroy$ = new Subject<boolean>();

  constructor(
    private composerMailService: ComposerMailService,
    private configurationService: ConfigurationService,
    private classifierLogService: ClassifierLogService,
    private loadingStateService: LoadingStateService,
    private matDialog: MatDialog,
    private mailReviewService: MailReviewService,
    private sdApiService: SdApiService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.sdApiService.loadUserClientsSDRs();
    this.configurationService.loadCustomers();

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((value) => (this.isLoading = value));

    this.mailReviewService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;

      if (this.selectedInteraction) {
        this.getClientRepresentatives();
      }
    });

    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.selectedMessage = message;
    });

    this.mailReviewService.currentStatusScreen$.pipe(takeUntil(this.destroy$)).subscribe((statusScreen) => {
      this.classifierMessageDto.statusScreen = statusScreen;
      this.isReview = statusScreen === StatusScreen.Review;
      this.loadManualReviewMessages();
    });

    this.mailReviewService.navigationButtonsDisable$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.navigationButtonsDisable = value;
    });
  }

  getClientRepresentatives() {
    this.configurationService.getClientRepresentatives(this.selectedInteraction.sdrId);
  }

  ngAfterViewInit(): void {
    this.composerMailService.isMailComposerMediumOpen
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpen) => isOpen && this.openMediumDialog());

    this.composerMailService.isMailComposerMinimized
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMinimized) => isMinimized && this.openMinimizedDialog());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isPrevBtnDisabled(): boolean {
    return (
      this.isLoading || this.navigationButtonsDisable || this.currentMessageIdx === 0 || !this.classifierMessages.length
    );
  }

  get isNextBtnDisabled(): boolean {
    return (
      this.isLoading ||
      this.navigationButtonsDisable ||
      this.totalCount - this.completedCases === this.currentMessageIdx + 1 ||
      !this.classifierMessages.length
    );
  }

  get messageExits(): boolean {
    return this.currentMessageIdx < this.classifierMessages.length - 1;
  }

  onNavigateNextMessage() {
    this.clearSearchValues();

    if (this.messageExits) {
      this.currentMessageIdx += 1;
      this.currentMessage = this.classifierMessages[this.currentMessageIdx];
      this.setClassifierMessage(this.currentMessage);
    } else {
      this.mailReviewService.setClassifierMessage(null);

      const previousPage = this.classifierMessageDto.page;
      if (this.classifierMessages.length === 0) {
        this.classifierMessageDto.page = 1;
      } else {
        this.classifierMessageDto.page += 1;
      }

      this.loadManualReviewMessages().catch(() => {
        this.classifierMessageDto.page = previousPage;
      });
    }
  }

  onNavigatePreviousMessage() {
    this.clearSearchValues();
    if (this.currentMessageIdx > 0) {
      this.currentMessageIdx -= 1;
      this.currentMessage = this.classifierMessages[this.currentMessageIdx];
      this.setClassifierMessage(this.currentMessage);
    }
  }

  clearSearchValues() {
    this.mailReviewService.setClickedEmail('');
    this.mailReviewService.setClickedProspectId(null);
    this.mailReviewService.setSearchParams(null);
  }

  loadManualReviewMessages(): Promise<void> {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.mailReviewService
        .loadManualReviewMessages(this.classifierMessageDto)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => (this.isLoading = false)),
        )
        .subscribe({
          next: (response: ClassifierMessageResult) => {
            this.classifierMessages =
              this.classifierMessageDto.page === 1 ? response.items : this.classifierMessages.concat(...response.items);
            this.setData(response);

            if (response?.items?.length === 0) {
              setTimeout(() => {
                this.snackBarService.showWarning('No more mails available');
              }, 1500);
            }
            resolve();
          },
          error: (err) => {
            this.snackBarService.showError('An error occurred while trying to get the emails');
            reject(err);
          },
        });
    });
  }

  async onRefreshMails() {
    this.classifierMessageDto.page = 1;
    await this.loadManualReviewMessages();
  }

  onCompleteCase() {
    if (this.isReview) {
      this.matDialog
        .open(CompleteCaseDialogComponent, {
          width: '30%',
          maxWidth: 'initial',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.completeCase(result);
          }
        });
    } else {
      this.completeManualCase();
    }
  }

  completeManualCase() {
    const classifierLogId = this.currentMessage?.classifierLogId;

    if (!classifierLogId) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to complete the case',
      );
      return;
    }

    this.loadingStateService.setLoadingState(true);
    this.classifierLogService
      .checkEnableUpdate(this.selectedMessage.classifierLogId)
      .pipe(
        retry(1),
        takeUntil(this.destroy$),
        catchError(() => {
          this.snackBarService.showError('Failed to mark as valid');
          return EMPTY;
        }),
        switchMap((response) => {
          if (response) {
            return this.mailReviewService.completeManualCase(classifierLogId);
          } else {
            this.snackBarService.showWarning('You must perform a classification before you can complete the case');
            return EMPTY;
          }
        }),
        catchError(() => {
          this.snackBarService.showError('An error occurred while trying to classify');
          return EMPTY;
        }),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Case completed successfully');
          this.refreshCases();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while completing the case');
        },
      });
  }

  completeCase(ruleId: number) {
    const completeCaseDto: CompleteCaseDto = {
      classifierLogId: this.currentMessage?.classifierLogId,
      ruleId,
    };

    if (!Object.values(completeCaseDto).every((value) => value)) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to complete the case',
      );
      return;
    }

    this.loadingStateService.setLoadingState(true);

    this.mailReviewService
      .completeCase(completeCaseDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.loadingStateService.setLoadingState(false);
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.showSuccess('Case completed successfully');
          this.refreshCases();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while completing the case');
        },
      });
  }

  refreshCases() {
    this.classifierMessages = this.classifierMessages.filter(
      (m) => m.classifierLogId !== this.currentMessage.classifierLogId,
    );

    if (this.classifierMessages.length) {
      this.currentMessageIdx = Math.min(this.currentMessageIdx, this.classifierMessages.length - 1);
      this.currentMessage = this.classifierMessages[this.currentMessageIdx];
    } else {
      this.currentMessageIdx = 0;
      this.currentMessage = null;
      this.classifierMessageDto.page = 1;
      this.loadManualReviewMessages();
    }

    this.pendingCount -= 1;
    this.completedCases += 1;

    if (this.currentMessage) {
      this.setClassifierMessage(this.currentMessage);
    } else {
      this.mailReviewService.setClassifierMessage(null);
    }
  }

  private setData(response: ClassifierMessageResult) {
    this.totalCount = response.totalResults;
    this.pendingCount = response.totalResults;
    this.completedCases = this.classifierMessageDto.page === 1 ? 0 : this.completedCases;
    this.currentMessageIdx = this.classifierMessageDto.page === 1 ? 0 : (this.currentMessageIdx += 1);

    this.currentMessage = this.classifierMessages?.length ? this.classifierMessages[this.currentMessageIdx] : null;
    this.setClassifierMessage(this.currentMessage);
    this.isLoading = false;
  }

  setClassifierMessage(classifierMessage: ClassifierMessage) {
    this.matDialog.closeAll();

    if (classifierMessage?.classifierLogId) {
      this.mailReviewService.setClassifierMessage(classifierMessage);
    }
  }

  onClickEmail(email: string) {
    this.mailReviewService.setClickedEmail(email);
  }

  openMediumDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');
    const dialModalRef = this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      maxHeight: '98vh',
    });
    dialModalRef.updatePosition({ bottom: '0', right: '20px' });
  }

  openMinimizedDialog() {
    const dialogData = JSON.parse(window.localStorage.getItem('fwdEmailData') || '{}');

    this.matDialog.open(MailComposerMinComponent, {
      panelClass: 'mail-composer-container',
      hasBackdrop: false,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
      width: '400px',
      height: '36px',
      position: { bottom: '0', right: '20px' },
    });
  }
}
