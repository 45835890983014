import { Component, Inject, Injectable } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private currentSnackbarRef: MatSnackBarRef<any> | null = null;
  private currentMessage: string = '';

  private baseOptions: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    duration: 5000,
  };

  private successOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    panelClass: ['snackbar-success'],
  };

  private warningOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    panelClass: ['snackbar-warning'],
  };

  private errorOptions: MatSnackBarConfig = {
    ...this.baseOptions,
    duration: 10000,
    panelClass: ['snackbar-error'],
  };

  constructor(private matSnackBar: MatSnackBar) {}

  /**
   * Displays a SnackBar with the specified message and configuration.
   *
   * @param message - Message to display to the user.
   * @param closeMessage - Message for the close action.
   * @param config - Base configuration for the SnackBar.
   * @param optionsOverride - Additional configuration to override defaults.
   */
  private showSnackBar(
    message: string,
    closeMessage: string,
    config: MatSnackBarConfig,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    const finalConfig = { ...config, ...optionsOverride };

    if (this.currentSnackbarRef) {
      this.currentMessage += `<br> <br> ${message}`;
      this.currentSnackbarRef.dismiss();
      this.currentSnackbarRef = this.matSnackBar.openFromComponent(SnackBarContentComponent, {
        data: { message: this.currentMessage },
        ...finalConfig,
      });
    } else {
      this.currentMessage = message;
      this.currentSnackbarRef = this.matSnackBar.open(message, closeMessage || 'Close', finalConfig);

      this.currentSnackbarRef.afterDismissed().subscribe(() => {
        this.currentSnackbarRef = null;
        this.currentMessage = '';
      });
    }
  }

  /**
   * Displays a success message in the SnackBar.
   *
   * @param message - Message to display to the user.
   * @param closeMessage - Message for the close action.
   * @param optionsOverride - Additional configuration to override defaults.
   */
  public showSuccess(
    message: string = 'The action was completed successfully',
    closeMessage?: string,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    this.showSnackBar(message, closeMessage, this.successOptions, optionsOverride);
  }

  /**
   * Displays a warning message in the SnackBar.
   *
   * @param message - Message to display to the user.
   * @param closeMessage - Message for the close action.
   * @param optionsOverride - Additional configuration to override defaults.
   */
  public showWarning(message: string = '', closeMessage?: string, optionsOverride?: MatSnackBarConfig): void {
    this.showSnackBar(message, closeMessage, this.warningOptions, optionsOverride);
  }

  /**
   * Displays an error message in the SnackBar.
   *
   * @param message - Message to display to the user.
   * @param closeMessage - Message for the close action.
   * @param optionsOverride - Additional configuration to override defaults.
   */
  public showError(
    message: string = 'An error has occurred',
    closeMessage?: string,
    optionsOverride?: MatSnackBarConfig,
  ): void {
    this.showSnackBar(message, closeMessage, this.errorOptions, optionsOverride);
  }

  /**
   * Closes the current SnackBar if it is open.
   */
  public closeCurrentSnackbar(): void {
    if (this.currentSnackbarRef) {
      this.currentSnackbarRef.dismiss();
      this.currentSnackbarRef = null;
      this.currentMessage = '';
    }
  }
}

@Component({
  selector: 'app-snack-bar-content',
  template: `
    <div class="custom-snackbar-content">
      <span [innerHTML]="data.message"></span>
      <button
        mat-button
        (click)="closeSnackBar()"
      >
        {{ data.closeMessage || 'Close' }}
      </button>
    </div>
  `,
  styles: [
    `
      .custom-snackbar-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 4px;

        button {
          color: white;
        }
      }
    `,
  ],
  imports: [MatButtonModule],
  standalone: true,
})
export class SnackBarContentComponent {
  closeMessage: string;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackBarContentComponent>,
  ) {
    this.closeMessage = data.closeMessage || 'Close';
  }

  closeSnackBar() {
    this.snackBarRef.dismiss();
  }
}
