import { Component, Input, OnInit } from '@angular/core';
import { SendStatusType } from 'src/app/model/SendStatusType';
import { FeedService } from 'src/app/services/feed.service';
import { Email } from '../../../../../model/Email';
import { SelectedMailService } from '../../../../../services/selected-mail.service';
import { Attachment } from 'src/app/model/Attachment';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';

@Component({
  selector: 'app-mail-feed-item',
  templateUrl: './mail-feed-item.component.html',
  styleUrls: ['./mail-feed-item.component.scss'],
})
export class MailFeedItemComponent implements OnInit {
  @Input() email: Email;
  @Input() selectedMailId = '';
  SendStatusType = SendStatusType;
  selectedFolder = '';
  isLoading = false;

  constructor(
    private selectedMailService: SelectedMailService,
    private feedService: FeedService,
    private messageAttachmentService: MessageAttachmentService,
  ) {}

  ngOnInit() {
    this.feedService.selectedFolder.subscribe((folder) => {
      this.selectedFolder = folder;
    });
  }

  get isSelected(): boolean {
    return this.email.storageId === this.selectedMailId || this.selectedMailService.isMailInBulkSelection(this.email);
  }

  async select() {
    this.isLoading = true;
    try {
      await this.convertCidToContent(this.email.bodyHtml, this.email.attachments, this.email.storageId);
      this.selectedMailService.selectMail(this.email);
      this.feedService.setShowFeed(false);
    } finally {
      this.isLoading = false;
    }
  }

  toggleSelect() {
    this.selectedMailService.toggleMailInBulkSelection(this.email);
  }

  getArgentinianDate() {
    return new Date(this.email.date + 'Z').toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
  }

  transform() {
    return new Date(this.email.date + 'Z').toLocaleString();
  }

  async convertCidToContent(html: string, attachments: Attachment[], messageHandlerId: string): Promise<string> {
    if (!html) {
      return html;
    }

    const regex = /<img.*?src=["']cid:([\w@.-]+)["'].*?>/;
    let lastHtml: string | null = null;

    while (true) {
      if (html === lastHtml) {
        return html;
      }

      lastHtml = html;
      const match = html.match(regex);

      if (!match || match.length < 2) {
        return html;
      }

      const [fullMatch, cidMatch] = match;
      const cid = cidMatch;

      const newRef = await this.loadCidAndGetImage(cid, attachments, messageHandlerId);

      if (!newRef) {
        console.warn(`No se encontró una referencia válida para CID: ${cid}`);
        return html;
      }

      html = html.replace(fullMatch, `<img src="${newRef}" data-cid="${cid}" data-manipulated="true">`);
      this.email.bodyHtml = html;
    }
  }

  async loadCidAndGetImage(cid: string, attachments: Attachment[], messageHandlerId: string): Promise<string> {
    for (const attachment of attachments) {
      if (cid === attachment.contentId) {
        try {
          const content = await this.messageAttachmentService.getContentFile(
            attachment.filename,
            attachment.isSafe,
            messageHandlerId,
          );
          return content;
        } catch (error) {
          console.error('Error en la llamada a la API:', error);
        }
      }
    }
    console.warn(`No se encontró contenido para CID: ${cid}`);
    return '';
  }
}
