<mat-accordion
  class="interactions"
  *ngIf="interactionsData && !isLoadingInteractions"
>
  <h3>Interactions</h3>
  <mat-expansion-panel disabled>
    <mat-expansion-panel-header>
      <div class="interactions__header interactions__header--bold">
        <span class="interactions__header-status">Status</span>
        <span class="interactions__header-interaction-type">Interaction Type</span>
        <span class="interactions__header-created-date">Date Created</span>
        <span class="interactions__header-interaction-date">Date Interaction</span>
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-forwarder"
          >Forwarder ID</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-campaing"
          >Campaing ID</span
        >
      </div>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
  <mat-expansion-panel
    *ngFor="let interaction of interactionsData?.interactions"
    (opened)="setCurrentInteraction(interaction)"
    (closed)="setCurrentInteraction(null)"
  >
    <mat-expansion-panel-header>
      <div
        class="interactions__header interactions__header--light"
        [ngClass]="{
          'interactions__header--selected': selectedInteraction?.interactionId === interaction.interactionId
        }"
      >
        <span class="interactions__header-status">{{ interaction.prospectStatus }}</span>
        <span class="interactions__header-interaction-type">{{ interaction.interactionType }}</span>
        <span class="interactions__header-created-date">{{
          interaction.utcDatetimeCreated | date: 'YYYY-MM-dd h:mm a'
        }}</span>
        <span class="interactions__header-interaction-date">{{
          interaction.utcDatetimeInteraction | date: 'YYYY-MM-dd h:mm a'
        }}</span>
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-forwarder"
          >{{ interaction?.forwardedContactId }}</span
        >
        <span
          *ngIf="!isMobileOrTablet"
          class="interactions__header-campaing"
          >{{ interaction?.campaignId || '-' }}</span
        >
      </div>
    </mat-expansion-panel-header>
    <app-interaction-item
      [contact]="interactionsData?.contact"
      [company]="interactionsData?.companyData"
      [interaction]="interaction"
    ></app-interaction-item>
    <div class="interactions__actions">
      <button
        *ngIf="
          interaction.prospectStatus !== classificationStatus.Sent &&
          interaction.prospectStatus !== classificationStatus.ConnectionRequestAccepted
        "
        mat-stroked-button
        (click)="onClickUndoInteraction(interaction)"
      >
        UNDO
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div
  *ngIf="isLoadingInteractions"
  class="progress-spinner"
>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div
  class="interactions__not-results"
  *ngIf="!interactionsData && !isLoadingInteractions"
>
  <mat-icon
    class="interactions__info-icon"
    color="warn"
    >info</mat-icon
  >
  No interactions found
</div>
