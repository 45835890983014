import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { Conversation, SdrConversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input() sdrConversation: SdrConversation;
  @Input() conversations: Conversation[];

  selectedConversation: Conversation;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private sdrConversationsService: SdrConversationsService,
    private snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.sdrConversationsService.selectedConversation$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe({
        next: (selectedConversation: Conversation) => {
          if (
            selectedConversation &&
            selectedConversation?.conversationId !== this.selectedConversation?.conversationId
          ) {
            this.selectedConversation = { ...selectedConversation };
            this.loadConversationMessages(this.selectedConversation?.conversationId);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  selectConversation(conversation: Conversation) {
    if (conversation.conversationId !== this.selectedConversation?.conversationId) {
      this.sdrConversationsService.setSelectedConversation(conversation);
    }
  }

  private loadConversationMessages(conversationId: string) {
    this.sdrConversationsService.setIsLoadingConversationMessages(true);
    this.sdrConversationsService
      .getMessagesByConversationId(conversationId)
      .pipe(finalize(() => this.sdrConversationsService.setIsLoadingConversationMessages(false)))
      .subscribe({
        next: (conversationMessage) => {
          this.sdrConversationsService.setCurrentConversationMessages(conversationMessage);
        },
        error: () => {
          this.sdrConversationsService.setCurrentConversationMessages(null);
          this.snackbarService.showError('Error loading messages of the conversation');
        },
      });
  }
}
