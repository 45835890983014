import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Contact } from '../model/common/contact.model';
import { GenericResponse } from '../model/common/generic-response';
import { HistoryInteraction } from '../model/common/history-interaction.model';
import { Interaction } from '../model/common/interaction.model';
import { CompleteCaseDto } from '../model/complete-case-dialog/complete-case.dto';
import { Email } from '../model/Email';
import { ClassifierMessageDto } from '../model/mail-classifier/classifier-message.dto';
import { ClassifierMessage, ClassifierMessageResult } from '../model/mail-classifier/classifier-message.model';
import { ClassifyReviewEmailDto } from '../model/mail-classifier/classify-review-email.dto';
import { LeadSearchParams } from '../model/mail-classifier/lead-search-params.model';
import { MessageToClassifyDto } from '../model/mail-classifier/message-to-classify.dto';
import { UpdateLeadInfoDto } from '../model/mail-classifier/update-lead-info.dto';
import { StatusScreen } from '../model/common/status-screen.enum';
import { Lead } from '../model/lead/lead.model';

@Injectable({
  providedIn: 'root',
})
export class MailReviewService {
  EDITABLE_CUSTOMERS_IDS = [4, 31, 42];
  private newSearchSubject = new BehaviorSubject<LeadSearchParams>(null);
  private selectedInteractionSubject = new BehaviorSubject<Interaction>(null);
  private selectedHistoryInteractionSubject = new BehaviorSubject<HistoryInteraction>(null);
  private currentContactSubject = new BehaviorSubject<Contact>(null);
  private classifierMessageSubject = new BehaviorSubject<ClassifierMessage>(null);
  private classifierEmailSubject = new BehaviorSubject<Email>(null);
  private clickedEmailSubject = new BehaviorSubject<string>('');
  private clickedProspectId = new BehaviorSubject<number>(null);
  private convertedInteractionSubject = new BehaviorSubject<Interaction>(null);
  private convertedChannelInteractionSubject = new BehaviorSubject<Interaction>(null);
  private currentStatusScreen = new BehaviorSubject<StatusScreen>(null);
  private contactFromForwarder = new BehaviorSubject<Lead>(null);
  private isContactDialogOpenSubject = new BehaviorSubject<boolean>(false);
  private navigationButtonsDisable = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  get currentClassifierMessage$() {
    return this.classifierMessageSubject.asObservable();
  }

  get currentClassifierEmail$() {
    return this.classifierEmailSubject.asObservable();
  }

  get currrentContact$() {
    return this.currentContactSubject.asObservable();
  }

  get clickedEmailToSearch$() {
    return this.clickedEmailSubject.asObservable();
  }

  get clickedProspectId$() {
    return this.clickedProspectId.asObservable();
  }

  get selectedInteraction$() {
    return this.selectedInteractionSubject.asObservable();
  }

  get newSearchParams() {
    return this.newSearchSubject.asObservable();
  }

  get convertedInteraction$() {
    return this.convertedInteractionSubject.asObservable();
  }

  get selectedHistoryInteraction$() {
    return this.selectedHistoryInteractionSubject.asObservable();
  }

  get currentStatusScreen$() {
    return this.currentStatusScreen.asObservable();
  }

  get contactFromForwarder$() {
    return this.contactFromForwarder.asObservable();
  }

  get convertedChannelInteraction$() {
    return this.convertedChannelInteractionSubject.asObservable();
  }
  get isContactDialogOpen$() {
    return this.isContactDialogOpenSubject.asObservable();
  }

  get navigationButtonsDisable$() {
    return this.navigationButtonsDisable.asObservable();
  }

  setNavigationButtonsDisable(isDisabled: boolean) {
    this.navigationButtonsDisable.next(isDisabled);
  }

  setContactDialogOpen(isOpen: boolean) {
    this.isContactDialogOpenSubject.next(isOpen);
  }

  setConvertedChannelInteraction(interaction: Interaction) {
    this.convertedChannelInteractionSubject.next(interaction);
  }

  setCurrentStatusScreen(statusScreen: StatusScreen) {
    this.currentStatusScreen.next(statusScreen);
  }

  setSelectedHistoryInteraction(interaction: HistoryInteraction) {
    this.selectedHistoryInteractionSubject.next(interaction);
  }

  setClassifierMessage(classifierMessage: ClassifierMessage) {
    this.classifierMessageSubject.next(classifierMessage);
  }

  setClassifierEmail(classifierMessage: Email) {
    this.classifierEmailSubject.next(classifierMessage);
  }

  setCurrentContact(contact: Contact) {
    this.currentContactSubject.next(contact);
  }

  setClickedEmail(email: string) {
    this.clickedEmailSubject.next(email);
  }

  setClickedProspectId(prospectId: number) {
    this.clickedProspectId.next(prospectId);
  }

  setSelectedInteraction(interaction: Interaction) {
    this.selectedInteractionSubject.next(interaction);
  }

  setSearchParams(params: LeadSearchParams) {
    this.newSearchSubject.next(params);
  }

  setConvertedInteraction(interaction: Interaction) {
    this.convertedInteractionSubject.next(interaction);
  }

  setContactFromForwarder(contact: Lead) {
    this.contactFromForwarder.next(contact);
  }

  loadManualReviewMessages(classifierMessageDto: ClassifierMessageDto): Observable<ClassifierMessageResult> {
    return this.httpClient
      .post(`${environment.newApiBaseUrl}/classifier-log/get-manual-review-messages`, classifierMessageDto)
      .pipe(map((response: GenericResponse<ClassifierMessageResult>) => response.data));
  }

  classifyEmail(messageToClassifyDto: MessageToClassifyDto): Observable<GenericResponse<null>> {
    const url = `${environment.newApiBaseUrl}/classification/classify-message`;

    return this.httpClient.post<GenericResponse<null>>(url, messageToClassifyDto);
  }

  classifyEmailMatched(classifyReviewEmailDto: ClassifyReviewEmailDto): Observable<GenericResponse<null>> {
    const url = `${environment.newApiBaseUrl}/classification/matched/classify-message`;

    return this.httpClient.post<GenericResponse<null>>(url, classifyReviewEmailDto);
  }

  completeCase(completeCaseDto: CompleteCaseDto): Observable<any> {
    const { classifierLogId, ruleId } = completeCaseDto;
    return this.httpClient.patch(
      `${environment.newApiBaseUrl}/classifier-log/${classifierLogId}/process/${ruleId}`,
      {},
    );
  }

  completeManualCase(classifierLogId: number): Observable<any> {
    return this.httpClient.patch(`${environment.newApiBaseUrl}/classifier-log/${classifierLogId}/process`, {});
  }

  updateLeadInfo(contactId: number, updateLeadInfoDto: UpdateLeadInfoDto) {
    const url = `${environment.newApiBaseUrl}/leads/${contactId}/info`;

    return this.httpClient.patch(url, updateLeadInfoDto);
  }
}
