import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { Contact } from '../../../model/common/contact.model';
import { Interaction } from '../../../model/common/interaction.model';
import { ProspectInfoDto } from '../../../model/common/prospect-info.dto';
import { ProspectInfo } from '../../../model/common/prospect-info.model';
import { Email } from '../../../model/Email';
import { ClassifierMessage } from '../../../model/mail-classifier/classifier-message.model';
import { LeadSearchParams } from '../../../model/mail-classifier/lead-search-params.model';
import { InteractionService } from '../../../services/interaction.service';
import { LeadService } from '../../../services/lead.service';
import { LeadsToBulkService } from '../../../services/leads-to-bulk.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { ContactDialogComponent } from '../../shared/contact-dialog/contact-dialog.component';
import { UndoInteractionDialogComponent } from '../../shared/undo-interaction-dialog/undo-interaction-dialog.component';

@Component({
  selector: 'app-mail-classifier-search-leads',
  templateUrl: './mail-classifier-search-leads.component.html',
  styleUrls: ['./mail-classifier-search-leads.component.scss'],
})
export class MailClassifierSearchLeadsComponent implements OnInit, OnDestroy {
  isLoading = false;
  searchResult: ProspectInfo = null;
  salesRepresentatives = [];
  selectedInteraction: Interaction = null;
  searchParams = null;
  noResultsMessage = '';
  currentMessage: ClassifierMessage = null;
  currentEmail: Email = null;
  currentContact: Contact = null;
  convertedInteraction: Interaction = null;
  convertedChannelInteraction: Interaction = null;
  classificationStatus = ClassificationStatusEnum;
  isContactDialogOpen$ = combineLatest([this.mailReviewService.isContactDialogOpen$]).pipe(
    map(([isContactDialogOpen]) => {
      return isContactDialogOpen;
    }),
  );
  destroy$ = new Subject<boolean>();

  constructor(
    private interactionService: InteractionService,
    private leadService: LeadService,
    private leadsToBulkService: LeadsToBulkService,
    private mailReviewService: MailReviewService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.interactionService.interactionsUpdated$.pipe(takeUntil(this.destroy$)).subscribe((updated) => {
      if (updated) {
        this.refreshInteractions();
      }
    });

    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.currentMessage = null;
      this.searchResult = null;
      this.mailReviewService.setClickedEmail('');
      this.isLoading = false;

      if (message) {
        this.currentMessage = message;

        const prospectInfo: ProspectInfo = {
          interactionsData: message?.interactionsData || null,
          companyData: message?.companyData || null,
        };

        this.searchResult = Object.values(prospectInfo).every((value) => value) ? prospectInfo : null;

        this.updateDataOnInteractionChange();
      } else {
        this.noResultsMessage = '';
      }
    });

    this.mailReviewService.currentClassifierEmail$.pipe(takeUntil(this.destroy$)).subscribe((email) => {
      this.currentEmail = email;
    });

    this.mailReviewService.newSearchParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params?.email && params?.sdrId) {
        this.onExecuteSearch(params);
      }
    });

    this.mailReviewService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;
    });

    this.mailReviewService.currrentContact$.pipe(takeUntil(this.destroy$)).subscribe((contact) => {
      this.currentContact = contact;
    });

    this.mailReviewService.convertedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.convertedInteraction = interaction ? interaction : null;
    });

    this.mailReviewService.convertedChannelInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.convertedChannelInteraction = interaction ? interaction : null;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onExecuteSearch(searchParams: LeadSearchParams) {
    this.isLoading = true;
    this.searchParams = searchParams;
    this.searchResult = null;

    this.leadsToBulkService
      .simpleSearchLeadToBulk(searchParams)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe({
        next: (response: ProspectInfo) => {
          this.searchResult = response || null;
          this.noResultsMessage = response ? '' : "We couldn't find any results matching the search criteria";
          this.updateDataOnInteractionChange();
        },
        error: () => this.snackBarService.showError('An error occurred while trying to get the interactions'),
      });
  }

  updateDataOnInteractionChange() {
    this.setConvertedInteraction();
    this.setConvertedChannelInteraction();
    this.mailReviewService.setSelectedInteraction(this.getMostRecentInteraction() || null);
    this.mailReviewService.setCurrentContact(this.searchResult?.interactionsData?.contact || null);
  }

  setConvertedInteraction() {
    const convertedInteraction = this.searchResult?.interactionsData?.interactions?.find(
      (i) => i?.prospectStatus === ClassificationStatusEnum.ConvertedLead,
    );

    this.mailReviewService.setConvertedInteraction(convertedInteraction || null);
  }

  setConvertedChannelInteraction() {
    const convertedChannelInteraction = this.searchResult?.interactionsData?.interactions?.find(
      (i) => i?.prospectStatus === ClassificationStatusEnum.Converted_Channel,
    );

    this.mailReviewService.setConvertedChannelInteraction(convertedChannelInteraction || null);
  }

  setSelectedInteraction(interaction: Interaction) {
    if (
      !this.convertedInteraction ||
      interaction?.prospectStatus == ClassificationStatusEnum.ConvertedLead ||
      !this.convertedChannelInteraction ||
      interaction?.prospectStatus == ClassificationStatusEnum.Converted_Channel
    ) {
      this.mailReviewService.setSelectedInteraction(interaction);
    }
  }

  refreshInteractions() {
    const prospectInfoDto: ProspectInfoDto = {
      contactId: this.leadService.currentContactIdSubject.getValue() || this.currentContact?.contactId,
      sdrId: this.currentMessage.sdrId,
    };

    this.searchResult = null;
    if (prospectInfoDto.contactId) {
      this.isLoading = true;
      this.interactionService
        .getFullProspectInfo(prospectInfoDto)
        .pipe(
          finalize(() => {
            this.interactionService.setInteractionsUpdated(false);
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (response: ProspectInfo) => {
            this.searchResult = response || null;
            this.noResultsMessage = response ? '' : "We couldn't find any results matching the search criteria";
            this.updateDataOnInteractionChange();
          },
          error: () => this.snackBarService.showError('An error occurred while trying to get the interactions'),
        });
    }
  }

  onClickViewLead() {
    this.dialog
      .open(ContactDialogComponent, {
        minHeight: '530px',
        maxWidth: 'initial',
        panelClass: 'view-lead-dialog',
        hasBackdrop: false,
        data: {
          emailAddress: this.currentContact?.emailAddress,
          contactId: this.currentContact?.contactId,
          customerId: this.currentMessage?.customerId,
          sdrId: this.currentMessage?.sdrId,
          messageId: this.currentMessage?.email?.messageId,
        },
      })
      .beforeClosed()
      .subscribe(() => {
        this.leadService.setIsCreatingContact(false);
      });
  }

  getMostRecentInteraction(): Interaction {
    if (this.convertedInteraction) {
      return this.convertedInteraction;
    }

    if (this.convertedChannelInteraction) {
      return this.convertedChannelInteraction;
    }

    return this.searchResult?.interactionsData?.interactions
      .filter((interaction) => interaction?.prospectStatus === ClassificationStatusEnum.Sent)
      .sort(
        (a, b) => new Date(b?.utcDatetimeInteraction)?.getTime() - new Date(a?.utcDatetimeInteraction)?.getTime(),
      )[0];
  }

  onClickUndoInteraction(interaction: Interaction) {
    this.dialog.open(UndoInteractionDialogComponent, {
      width: '30%',
      maxWidth: 'initial',
      data: {
        interactionId: interaction.interactionId,
        customerId: interaction.customerId,
        fromMail: this.currentEmail.from_.email,
        prospectStatusId: interaction.prospectStatusId,
      },
    });
  }
}
