export class MailCategory {
  id: string;
  name: string;
  order?: number;

  constructor(raw?: any) {
    if (!raw) {
      return;
    }

    this.id = raw.id;
    this.name = raw.name;
    this.order = raw.orderToShow;
  }
}

export interface IMailClassification {
  sectionName: string;
  classifications: MailCategory[];
}

export enum ClassificationEmailFeedEnum {
  DeleteFrom = '2',
  ToContact = '26',
  OutOfOffice = '11',
}
