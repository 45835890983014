<div
  *ngIf="messageSalesRepresentative"
  class="sales-rep"
>
  {{ messageSalesRepresentative }}
  <mat-icon
    class="sales-rep__clear"
    (click)="clearSalesRepresentative()"
    >close</mat-icon
  >
</div>
<div
  class="send-message"
  [ngClass]="{ 'send-message--with-sales-rep': messageSalesRepresentative }"
>
  <mat-form-field
    subscriptSizing="dynamic"
    class="send-message__field-message"
    id="form-field-message"
  >
    <textarea
      matInput
      #messageTextarea
      placeholder="Type a message"
      class="send-message__textarea"
      [formControl]="messageCtrl"
      (input)="adjustTextareaHeight(messageTextarea)"
    ></textarea>
  </mat-form-field>
  <button
    mat-icon-button
    (click)="selectSalesRep()"
    [disableRipple]="true"
    class="select-template-btn"
    matTooltip="Select Sales Representative"
    id="mail-composer-select-sales-representative-btn"
  >
    <mat-icon class="iconSelectTemplate">face</mat-icon>
  </button>
  <button
    mat-icon-button
    color="primary"
    [disabled]="!messageCtrl.value || !isConnectionValid"
    (click)="sendMessage()"
  >
    <mat-icon *ngIf="!isSendingMessage">send</mat-icon>
    <mat-spinner
      *ngIf="isSendingMessage"
      diameter="20"
    ></mat-spinner>
  </button>
</div>
